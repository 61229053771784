<template>
  <div>
    <template v-if="$options.components['advertisement-loading']">
      <advertisement-loading v-if="isLoading" />
    </template>
    <b-container>
      <b-row>
        <b-col md="5" class="ml-5">
          <h5 class="heading">Create Instore Order</h5>
          <v-text-field label="Enter Shop No" v-model="shopName" class="mt-5">
          </v-text-field>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            class="search-icon"
            @click="onClickSearch"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.09048 16.181C9.83693 16.181 11.4676 15.6128 12.7933 14.666L17.7801 19.6528C18.0116 19.8843 18.3167 20 18.6323 20C19.3161 20 19.8106 19.474 19.8106 18.8006C19.8106 18.485 19.7054 18.1904 19.474 17.959L14.5187 12.9932C15.5602 11.6255 16.181 9.93161 16.181 8.09048C16.181 3.64019 12.5408 0 8.09048 0C3.62967 0 0 3.64019 0 8.09048C0 12.5408 3.62967 16.181 8.09048 16.181ZM8.09048 14.4345C4.6081 14.4345 1.74645 11.5623 1.74645 8.09048C1.74645 4.61862 4.6081 1.74645 8.09048 1.74645C11.5623 1.74645 14.4345 4.61862 14.4345 8.09048C14.4345 11.5623 11.5623 14.4345 8.09048 14.4345Z"
              fill="#979797"
              fill-opacity="0.6"
            />
          </svg>
          <div v-if="searchSeller && show" class="add-background d-flex ml-3">
            <img
              class="ml-2"
              width="120px"
              :src="this.searchSeller.organization.logo.url"
              alt
            />
            <div>
              <span class="shop-name">{{
                searchSeller.office.officeName
              }}</span>
              <br />
              <span class="name">Shop #: {{ this.shopName }}</span>
            </div>
          </div>
          <div v-else class="add-background d-flex justify-content-left">
            <img
              class="ml-2"
              width="130px"
              height="100px"
              src="store-image.PNG"
              alt
            />
            <span class="shop-name">Search Seller</span>
            <br />
            <!-- <span class="name">Shop #</span> -->
          </div>

          <v-text-field
            label="Enter Order Amount (Excluding Tax)"
            v-model="amount"
            class="mt-5"
            @input="(e) => onAddAmount(e)"
          >
          </v-text-field>
          <div>
            <div v-if="show">
              <p class="invoice">Invoice Attachment</p>
              <b-form-file
                @change="readLogo"
                :state="Boolean(file1)"
                placeholder="Upload Invoice..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
              <div v-if="file1">
                <hr class="hr2" />
                <div class="text-right">
                  <b-button
                    variant="danger"
                    class="rounded-circle tx-20"
                    @click="file1 = ''"
                    >&times;</b-button
                  >
                </div>
                <div v-if="file1">
                  <b-img :src="file1" center class="my-2 image w-50" />
                </div>
                <hr class="hr2" />
              </div>
            </div>
          </div>
          <!-- <hr class="hr" v-if="show" /> -->
          <!-- <h5 class="heading">Upload Invoice</h5> -->
          <!-- <div class="upload"></div> -->
        </b-col>
        <b-col md="4" class="margin mt-5">
          <template v-if="$options.components['address-cart-address']">
            <address-cart-address />
          </template>
          <b-container>
            <div class="mb-3">
              <span class="order-header mb-5">Price Details</span>
            </div>
            <b-row class="w-200">
              <b-col>
                <b-card-text class="sub-total">Total:</b-card-text>
              </b-col>
              <b-col>
                <b-card-text class="price-details"
                  >₹ {{ this.amount === "" ? "0" : this.amount }}</b-card-text
                >
              </b-col>
              <hr class="hr" />
            </b-row>
            <b-row class="w-200">
              <b-col>
                <b-card-text class="sub-total">Total Amount:</b-card-text>
              </b-col>
              <b-col>
                <b-card-text class="price-details"
                  >₹
                  {{ this.amount === "" ? "0.00" : this.amount }}</b-card-text
                >
              </b-col>
            </b-row>
          </b-container>
          <hr class="hr2" />
          <template>
            <div class="transaction-benefits mt-5">
              <b-btn
                block
                href="#"
                v-b-toggle.accordion1
                variant="secondary"
                class="d-flex justify-content-between mt-4 benefitsbtn"
              >
                <div class="transaction-benefits-heading heading-trans">
                  <b-img
                    src="img/benefits-icon.png"
                    class="invert-color"
                  ></b-img
                  >Transaction Benefits
                </div>

                <span class="when-opened">
                  <b-icon icon="chevron-down"></b-icon>
                </span>
                <span class="when-closed">
                  <b-icon icon="chevron-right"></b-icon>
                </span>
              </b-btn>
              <b-collapse id="accordion1" role="tabpanel">
                <div class="showbenefits-div">
                  <b-card
                    title="Your Cash Back"
                    class="mb-4 txn-benefits-cards your-cashback"
                  >
                    <b-card-text class="mb-0 d-flex justify-content-between">
                      Dhamaka Cashback:
                      <p>
                        &#x20B9;
                        {{
                          transactionBenefits &&
                          transactionBenefits[0] &&
                          transactionBenefits[0].value > transactionBenefits &&
                          transactionBenefits[1] &&
                          transactionBenefits[1].value
                            ? (transactionBenefits &&
                                transactionBenefits[0] &&
                                transactionBenefits[0].value) ||
                              0
                            : (transactionBenefits &&
                                transactionBenefits[1] &&
                                transactionBenefits[1].value) ||
                              0
                        }}
                      </p>
                    </b-card-text>
                    <b-card-text class="d-flex justify-content-between">
                      Promotional Cashback:
                      <p>
                        &#x20B9;
                        {{
                          transactionBenefits && transactionBenefits[2]
                            ? transactionBenefits[2].value
                            : 0
                        }}
                      </p>
                    </b-card-text>
                  </b-card>
                  <b-card
                    title="Your Referrer Cash Back"
                    class="mb-4 txn-benefits-cards referrer-cashback"
                  >
                    <b-card-text class="mb-0 d-flex justify-content-between">
                      Double Dhamaka Cashback:
                      <p>
                        &#x20B9;
                        {{
                          transactionBenefits &&
                          transactionBenefits[0] &&
                          transactionBenefits[0].value > transactionBenefits &&
                          transactionBenefits[1] &&
                          transactionBenefits[1].value
                            ? (transactionBenefits &&
                                transactionBenefits[0] &&
                                transactionBenefits[0].value) ||
                              0
                            : (transactionBenefits &&
                                transactionBenefits[1] &&
                                transactionBenefits[1].value) ||
                              0
                        }}
                      </p>
                    </b-card-text>
                    <b-card-text class="d-flex justify-content-between">
                      Referrer Cashback:
                      <p>
                        &#x20B9;
                        {{
                          transactionBenefits && transactionBenefits[3]
                            ? transactionBenefits[3].value
                            : 0
                        }}
                      </p>
                    </b-card-text>
                  </b-card>
                  <b-card class="txn-benefits-cards your-own-akshaya-patra">
                    <b-card-text class="d-flex akshaya-patra"
                      >Your Own Akshaya Patra:</b-card-text
                    >
                    <p class="akshaya-patra-points">
                      {{
                        transactionBenefits && transactionBenefits[0]
                          ? transactionBenefits[0].value
                          : 0
                      }}
                    </p>
                  </b-card>
                </div>
              </b-collapse>
            </div>
          </template>
          <template>
            <p id="cashback" class="text"></p>
          </template>
        </b-col>
      </b-row>
    </b-container>

    <div class="text center button-margin">
      <b-button @click="submitApproval" variant="primary" class="mb-2 w-auto">
        Submit For Approval</b-button
      >
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Vue from "vue";
export default {
  name: "checkout",
  data() {
    return {
      isLoading: false,
      shopName: "",
      amount: "",
      show: false,
      file1: null,
    };
  },
  computed: {
    ...mapState({
      searchSeller: (state) => state.profile.searchSeller,
      sellerDetail: (state) => state.profile.sellerDetail,
      defaultAddress: (state) => state.address.defaultAddress,
      transactionBenefits: (state) => state.profile.transactionBenefits,
      userInfo: (state) => state.auth.userInfo,
    }),
  },

  methods: {
    async cashBackAccount() {
      try {
        await this.$store
          .dispatch("profile/getAccountDetails", this.userInfo._id)
          .then((res) => {
            console.log(res, "res");
            if (res.data.data.status === "SUCCESS") {
              console.log(res);
            }
          });
      } catch (e) {
        document.getElementById("cashback").innerText =
          "To receive cashbacks please add your bank details under cashback accounts.";
      }
    },
    async onAddAmount(e) {
      console.log(e);
      let data = {
        orderAmount: this.amount,
        provider: this.searchSeller._id,
      };
      await this.$store.dispatch("profile/getTransactionBenefits", data);
    },
    async submitApproval() {
      await this.$store.dispatch(
        "profile/fetchShopDetails",
        this.searchSeller._id
      );
      const payload = {
        customerDetails: {
          phone: this.userInfo.authentication.phone,
          email: this.userInfo.authentication.email,
          name: this.userInfo.name.first,
        },
        orderDetails: {
          paymentType: "COD",
          deliveryAddress: {
            address: this.defaultAddress,
            loc: this.defaultAddress.location,
          },
          items: [
            {
              ...this.sellerDetail[0],
              qty: 1,
              custom: {
                InvoiceAttachment: this.file1,
                shopImage: this.searchSeller.organization.logo.url,
                shopNumber: this.shopName,
              },
            },
          ],
          orderAmount: this.amount,
          totalPriceBeforeTax: this.amount,
        },
        payment: {
          type: "COD",
        },
      };

      await this.$store
        .dispatch("profile/createInstore", payload)
        .then(async (res) => {
          this.$store.state["checkout"].checkoutLoading = false;
          this.$router.push({
            name: "order-success",
            params: {
              orderId: res.orderId,
            },
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    readLogo(e) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        this.uploadImage(reader.result);
      };
      reader.onerror = (err) => {
        console.error("reader : ", err);
      };
    },
    showCustomToast() {
      Vue.toasted.show("Uploading...", {
        type: "info",
        theme: "toasted-primary",
        singleton: true,
        duration: 1000000,
      });
    },
    clearCustomToast() {
      Vue.toasted.clear();
    },
    async uploadImage(image) {
      let dataobj = {
        image: image,
        key: (
          Date.now().toString(36) + Math.random().toString(36).substr(2, 5)
        ).toLowerCase(),
        isPublic: true,
        replaceExisting: true,
        basePath: "tenant",
      };
      this.showCustomToast();
      await this.$store
        .dispatch("healthCare/uploadImageToStorage", dataobj)
        .then((res) => {
          if (res) {
            this.clearCustomToast();
            this.file1 = res.data.data.fileUrl;
          }
        })
        .catch((error) => {
          console.log(error);
          this.clearCustomToast();
          this.$toasted.error("Image not uploaded..please try again!");
        });
    },
    async onClickSearch() {
      console.log(this.shopName);
      const number = this.shopName;
      await this.$store.dispatch("profile/fetchSeller", number);
      this.show = true;
    },
    clearData() {
      (this.amount = ""), (this.shopName = "");
    },
  },
  mounted() {
    this.cashBackAccount();
  },
};
</script>
<style scoped>
.hr {
  width: 480px;
  font-weight: 10px;
  margin-left: 0px;
  line-height: 30px;
  border: normal;
}
.hr2 {
  margin-top: 20px;
}

.button-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-top: 40px;
  text-transform: capitalize;
  color: #fff;
}
.text {
  font-style: normal;
  color: rgb(240, 60, 60);
  padding: 18px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.heading {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #000000;
  line-height: 60px;
}

.sub-total {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 49px;
  color: #000000;
}
.invoice {
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 39px;
  margin-top: 20px;
  color: #000000;
}
.price-details {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 39px;
  margin-left: 100px;
  color: #000000;
}
.button-margin {
  margin-left: 540px;
}
.order-header {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  color: #000000;
}

.add-background {
  background: rgba(182, 182, 182, 0.08);
  mix-blend-mode: normal;
  padding: 20px;
  padding-left: 20px;
}
.shop-name {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #000000;
  line-height: 30px;
  margin-top: 30px;
  margin-left: 20px;
}
.name {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #000000;
  margin-left: 20px;
}
.search-icon {
  position: absolute;
  left: 453px;
  right: 787.19px;
  top: 130px;
  cursor: pointer;
  bottom: 1329px;
}
.background {
  position: absolute;
  height: 101px;
  left: 4.38%;
  right: 53.19%;
  top: calc(50% - 101px / 2 - 430px);
  background: rgba(182, 182, 182, 0.08);
  mix-blend-mode: normal;
}
.margin {
  margin-left: 150px;
}
.transaction-benefits .benefitsbtn {
  background-color: rgba(0, 0, 0, 0.03) !important;
  padding: 12px 10px;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;

  color: #272727 !important;
}
.color-transaction {
  background: linear-gradient(#ff1934, #ff4d35, #ff7f37);
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

.showbenefits-div {
  padding: 12px 12px;
  border: 1px solid #ddd;
  border-radius: 0px 0px 10px 10px;
}

.showbenefits-div ul {
  border-bottom: 1px solid #ddd;
}

.invert-color {
  filter: invert(1);
  color: white;
}

.transaction-benefits-heading {
  font-size: 18px;
  font-weight: 500;
}

.address-details {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 11px;
  text-transform: capitalize;
  color: #9e9e9e;
}

.text-danger {
  color: #fe7b08 !important;
}

.price-header {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.card-custom-class {
  border: none;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-bottom-color: rgba(0, 0, 0, 0.125);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-radius: 0.25rem;
}

.price-details {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

.price-total-amount {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 19px;
  color: #14982b;
}

.btn-outline-primary {
  width: 180px;
  height: 45px;
}

.btn-primary {
  width: 180px;
  height: 45px;
  border-radius: 3px;
}

.btn-outline-primary:hover {
  width: 180px;
  height: 45px;
}

.txn-benefits-cards {
  border-radius: 8px;
}

.your-cashback {
  background-image: linear-gradient(to right, #fc4f31, #ff7632);
  color: #ffff;
}

.referrer-cashback {
  background-image: linear-gradient(to right, #f5669c, #e44594);
  color: #ffff;
}

.your-own-akshaya-patra {
  background-image: linear-gradient(to right, #2a4ea7, #147f4b);
  color: #ffff;
}

.akshaya-patra-heading {
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 0;
  width: 70%;
  margin-right: 2%;
  float: left;
}

.akshaya-patra {
  font-size: 1.5rem;
  font-weight: 400;
  margin-top: 0;
  width: 100%;
  float: left;
}
.akshaya-patra-points {
  font-size: 1.6rem;
  margin-left: 20px;
  float: right;
}
</style>
